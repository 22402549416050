import { Link } from 'gatsby';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ArticlePiece from '../../components/ArticlePiece';
import QMCloudsOhjelmistoTaustaImage from '../../components/images/QMCloudsOhjelmistoTaustaImage';
import urls from '../../components/urls';
import Asset48 from '../../images/Asset-48.svg';
import Asset49 from '../../images/Asset-49.svg';
import Asset50 from '../../images/Asset-50.svg';
import Asset51 from '../../images/Asset-51.svg';
import Asset24 from '../../images/article-img.svg';
import Asset26 from '../../images/article-img2.svg';
import qualityManual from '../../images/quality-manual.svg';

export default () => {
  const { t } = useTranslation();
  return (
    <Container className="container-space">
      <Row className="mb-5">
        <Col>
          <h2 className="text-blue">{t('ArticlesTitle')}</h2>
        </Col>
      </Row>
      <Row>
        <ArticlePiece
          title={t('ArticlePiece8Title')}
          image={Asset48}
          imageAlt={t('ArticlePiece8ImageAlt')}
          paragraph={t('ArticlePiece8Paragraph')}
          link={urls.whatIsQualitySystemArticle}
        />
        <ArticlePiece
          title={t('ArticlePiece7Title')}
          image={Asset51}
          imageAlt={t('ArticlePiece7ImageAlt')}
          paragraph={t('ArticlePiece7Paragraph')}
          link={urls.stepByStepInstructionsForBuildingQualitySystemArticle}
        />
        <ArticlePiece
          title={t('ArticlePiece6Title')}
          image={Asset49}
          imageAlt={t('ArticlePiece6ImageAlt')}
          paragraph={t('ArticlePiece6Paragraph')}
          link={urls.whatDoesItCostToBuildQualitySystemArticle}
        />
        <ArticlePiece
          title={t('ArticlePiece5Title')}
          image={Asset50}
          imageAlt={t('ArticlePiece5ImageAlt')}
          paragraph={t('ArticlePiece5Paragraph')}
          link={urls.whyDoYouNeedQualityManagementSystemArticle}
        />
        <Col lg={4} className="d-flex mt-4">
          <Card>
            <QMCloudsOhjelmistoTaustaImage />
            <div className="card-body">
              <h3 className="text-blue text-center sub-title-size line-height-20">
                {t('ArticlePiece4Title')}
              </h3>
              <p className="text-center">{t('ArticlePiece4Paragraph')}</p>
            </div>
            <Link
              to={urls.implementedQualitySystemArticle}
              className="btn btn-primary article-piece-btn"
            >
              {t('ReadMore')}
            </Link>
          </Card>
        </Col>

        <ArticlePiece
          title={t('ArticlePiece1Title')}
          image={Asset24}
          imageAlt={t('ArticlePiece1Alt')}
          paragraph={t('ArticlePiece1Paragraph')}
          link={urls.deviationReportingArticle}
        />
        <ArticlePiece
          title={t('ArticlePiece2Title')}
          image={Asset26}
          imageAlt={t('ArticlePiece2ImageAlt')}
          paragraph={t('ArticlePiece2Paragraph')}
          link={urls.improvingQualityArticle}
        />
        <ArticlePiece
          title={t('ArticlePiece3Title')}
          image={qualityManual}
          imageAlt={t('ArticlePiece3ImageAlt')}
          paragraph={t('ArticlePiece3Paragraph')}
          link={urls.qualityManualUrl}
        />
      </Row>
    </Container>
  );
};
