import { Link } from 'gatsby';
import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  paragraph: string;
  image: string;
  imageAlt: string;
  link: string;
}

export default (props: Props) => {
  const { title, paragraph, image, imageAlt, link } = props;
  const { t } = useTranslation();
  return (
    <Col lg={4} className="d-flex mt-4">
      <Card>
        <img src={image} alt={imageAlt} className="img" />
        <div className="card-body">
          <h3 className="text-blue text-center sub-title-size line-height-20">
            {title}
          </h3>
          <p className="text-center">{paragraph}</p>
        </div>
        <Link to={link} className="btn btn-primary article-piece-btn">
          {t('ReadMore')}
        </Link>
      </Card>
    </Col>
  );
};
