import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import AllArticles from '../pages-partials/articles/AllArticles';
import ArticlesHero from '../pages-partials/articles/ArticlesHero';

const ArticlePage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('ArticlesSeoTitle')}
      description={t('ArticlesSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/articles"
      lang={locale}
    />

    <ArticlesHero />
    <AllArticles />
  </Layout>
);

export default withI18next({ ns: 'common' })(ArticlePage);
